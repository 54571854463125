<template>
  <v-overlay :absolute="absolute" dark z-index="9999" v-model="value">
    <v-container fluid class="ma-0 pa-0">
      <v-row class="ma-0 pa-0" justify="center" align="center">
        <v-progress-circular indeterminate class="mr-2" />
        <slot>Loading</slot>
      </v-row>
    </v-container>
  </v-overlay>
</template>

<script>
export default {
  name: 'ScreenDarkener',

  props: {
    absolute: {
      type: Boolean,
      default () {
        return false
      }
    },
    value: {
      type: Boolean,
      default: () => false
    }
  }
}
</script>
