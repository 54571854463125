<template>
  <v-menu :close-on-content-click="false" v-model="selectorOpen">
    <template v-slot:activator="{ on, attrs }">
      <v-text-field prepend-inner-icon="mdi-clock" :value="display"
        :label="label"
        :hint="hint"
        :placeholder="placeholder"
        :required="required"
        v-on="on"
        v-bind="attrs"
        readonly
        :disabled="disabled"
        :clearable="clearable"
      >
      </v-text-field>
    </template>

    <v-time-picker
      ampm-in-title
      :format="amPm ? 'ampm' : '24hr'"
      v-model="selectedTime"
      :color="color"
      :readonly="readonly"
      :disabled="disabled"
      @change="emitValue"
      full-width
    />
  </v-menu>
</template>

<script>
export default {
  name: 'TextboxTimepicker',

  props: {
    'am-pm': {
      type: Boolean,
      default: () => false
    },
    label: String,
    color: String,
    readonly: Boolean,
    disabled: Boolean,
    placeholder: String,
    required: Boolean,
    clearable: Boolean,
    hint: String,
    value: String
  },

  computed: {
    display () {
      var time = this.$data.selectedTime
      if (!this.amPm) return time

      var timeArray = time.split(':')
      if (timeArray[0] < 12) {
        timeArray[timeArray.length - 1] += ' AM'
      } else {
        timeArray[timeArray.length - 1] += ' PM'
      }
      timeArray[0] = parseInt(timeArray[0]) % 12

      return timeArray.join(':')
    }
  },

  data () {
    return ({
      selectorOpen: false,
      selectedTime: this.value
    })
  },

  watch: {
    value (v) {
      this.selectedTime = v
    }
  },

  methods: {
    emitValue (v) {
      this.$emit('input', v)
      this.$data.selectorOpen = false
    }
  }
}
</script>
